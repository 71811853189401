export default {
  name: 'en',
  base: {
    cancel: 'Cancel',
    signIn: 'Sign in',
    signUp: 'Sign up',
    signOut: 'Sign out',
    submitReservationForm: 'Booked for <=seats>',
    submitFilterForm: 'Search...',
    save: 'Save changes',
    anycafe: 'RESERVBLE',
    book: 'Book',
    selectTime: 'Select a time',
    or: 'Or',
    createAccount: 'Create account',
    back: 'Back',
    ok: 'Ok',
    total: 'Total',
  },
  cities: {
    odessa: 'Odessa',
    kyiv: 'Kyiv',
    ternopil: 'Ternopil',
    dnipro: 'Dnipro',
    lviv: 'Lviv',
    kharkiv: 'Kharkiv',
    uzhhorod: 'Uzhhorod',
    bilatserkv: 'Bila Tserkva',
    chernihiv: 'Chernihiv',
    podilsk: 'Podilsk',
    mukacheve: 'Mukachevo',
    helsinki: 'Helsinki',
  },
  currencies: {
    uah: 'UAH'
  },
  types: {
    likes: {
      add: '<=place> was added to favorites',
      delete: '<=place> was removed from favorites',
      addDish: 'Dish added to favorites',
      deleteDish: 'Dish removed from favorites',
    },
    user: {
      phone: 'Phone',
      phoneLower: 'phone',
      email: 'E-mail',
      emailLower: 'e-mail',
      password: 'Password',
      newPassword: 'New password',
      repeatPassword: 'Repeat password',
      name: 'Name',
      surname: 'Surname',
      rememberMe: 'Remember me',
    },
    reservation: {
      '@extends': 'types.user',
      button: 'Make reservation',
      comment: 'Do you have wishes?',
      date: 'Date/Time',
      status: {
        REQUESTED: 'Requested',
        ACCEPTED: 'Accepted',
        FINISHED: 'Finished',
        CANCELED: 'Cancelled',
        SKIPPED: 'Skipped',
        REJECTED: 'Rejected'
      }
    },
    filters: {
      city: 'Choose the city',
    },
    feedback: {
      thankYou: 'Thanks for your feedback',
    },
    join: {
      thankYou: 'Thank you for your request. Our administrator will contact you shortly.',
    }
  },

  errors: {
    '@root': 'An error has occured',
    '@any': '<.>',
    error: 'Error',
    server: 'Sorry, there are some problems with our API server',
    validation: {
      '@root': 'Wrong format',
      '@any': '<.>',
      required: 'This field is required',
      email: 'Please use correct <types.user.emailLower> format',
      phone: 'Please use correct <types.user.phoneLower> format',
      minLength: {
        1: 'Minimum <=symbols> symbol',
        2: 'Minimum <=symbols> symbols',
        5: 'Minimum <=symbols> symbols'
      },
      maxLength: {
        1: 'Maximum <=symbols> symbol',
        2: 'Maximum <=symbols> symbols',
        5: 'Maximum <=symbols> symbols',
      },
      minNumber: 'Minimum value is <=min>',
      maxNumber: 'Maximum value is <=max>',
      repeatPassword: 'Passwords do not match'
    },
    fields: {
      email: '<types.user.emailLower>',
      phone: 'phone',
      notUniq: 'User with such <=field> already exists'
    },
    common: {
      unauthorized: 'This action is allowed only for authorized users',
      wrongAuth: 'Incorrect phone or password. Check the correctness of the entered data',
      notFound: 'Requested entity not found',
      oldPasswordIsInvalid: 'Your current password is invalid. Please try again',
      justVisitorCanLeaveAComment: 'You can leave a comment only after a confirmed reservation',
      userWithSpecifiedEmailNotFound: 'The user with the specified email address was not found',
    },
    join: 'Sorry, there are some problems with our API server. Please send a message to our administrator to the phone number indicated in the footer',
  },

  containers: {
    SearchBar: {
      placeholder: 'Name, kitchen, type, address',
      search: 'Search',
      useAi: 'Use AI magic',
    },
    Header: {
      signIn: '<base.signIn>',
      signUp: '<base.signUp>',
      signOut: '<base.signOut>',
      placeOwner: 'For restaurants',
      join: 'Connect restaurant',
    },

    SortResults: {
      sortBy: 'Sort by:',
      sort: {
        alphabet: 'Alphabet',
        rating: 'Rating',
        nearest: 'Nearest',
        expensive: 'Most expensive',
        cheap: 'Most cheap',
        free: 'Most free tables',
      }
    },

    UserFeedbacks: {
      empty: 'You don\'t have any feedbacks yet',
      moreReviews: 'Show <=pageSize> more reviews'
    },

    MailingPreferences: {
      notifications: 'Send notifications on <types.user.emailLower>',
      discounts: 'Send info about discounts on <types.user.emailLower>',
      selections: 'Send restaurant selections on <types.user.emailLower>',
      save: '<base.save>'
    },

    UserPreferences: {
      city: 'Main city',
      cityPlaceholder: 'Your main city',
      allergy: 'Intolerance / Allergies',
      allergyPlaceholder: 'Indicate any intolerance or allergies you have',
      favoriteCuisine: 'Favorite cuisine',
      favoriteCuisinePlaceholder: 'Tell us about your favorite cuisine',
      favoriteDishes: 'Favorite dishes',
      favoriteDishesPlaceholder: 'Tell us about your favorite dishes',
      unliked: 'Unloved dishes',
      unlikedPlaceholder: 'Tell us what you don\'t like',
      save: '<base.save>'
    },

    UserProfile: {
      photo: {
        delete: 'Delete photo',
        new: 'New photo',
      },
      info: 'Info',
      stats: {
        cities: {
          1: 'city',
          2: 'cities',
          5: 'cities'
        },
        visits: {
          1: 'visit',
          2: 'visits',
          5: 'visits'
        },
        rates: {
          1: 'rate',
          2: 'rates',
          5: 'rates'
        },
        feedbacks: {
          1: 'feedback',
          2: 'feedbacks',
          5: 'feedbacks'
        }
      },
      achievement: {
        rates: 'rates on <base.anycafe>',
        bookings: 'reservations on <base.anycafe>'
      },
      data: {
        name: '<types.user.name>',
        namePlaceholder: 'Enter your name',
        surname: '<types.user.surname>',
        surnamePlaceholder: 'Enter your surname',
        phone: '<types.user.phone>',
        email: '<types.user.email>',
        emailPlaceholder: 'Enter your <types.user.emailLower>',
        password: '<types.user.password>',
        passwordPlaceholder: 'Enter your password',
        confirmPassword: 'Confirm password',
        confirmPasswordPlaceholder: 'Confirm your password',
        newPassword: '<types.user.newPassword>',
        newPasswordPlaceholder: '<types.user.newPassword>',
      },
      social: {
        link: 'Link social networks',
        linked: 'linked',
        notLinked: 'not linked',
        linkButton: 'Link',
        unlinkButton: 'Unlink'
      },
      save: '<base.save>',
    },

    QuickReservation: {
      book: '<base.book>',
      selectTime: '<base.selectTime>',
      noOptions: 'No options available for selected time',
      nextDay: 'Check next day'
    },

    TopUserMenu: {
      notifications: 'Notifications',
      currentReserve: 'Reserved by you',
      history: 'History',
      favorites: 'Favorites',
      profile: 'Profile',
      logout: '<base.signOut>'
    },

    FinishRegistrationModal: {
      lastStep: 'One last step to finish your registration :)',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      createAccount: '<base.createAccount>'
    },

    AskPhoneModal: {
      lastStep: 'One last step to finish your reservation :)',
      text: 'Please enter your phone number so administrator may contact you if needed',
      continue: 'Continue'
    },

    LoginRegisterModal: {
      signInTitle: 'Sign in to <base.anycafe>',
      signUpTitle: 'Welcome to <base.anycafe>',
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      password: '<types.user.password>',
      repeatPassword: '<types.user.repeatPassword>',
      rememberMe: '<types.user.rememberMe>',
      forgotPassword: 'Forgot password?',
      signIn: '<base.signIn>',
      signUp: '<base.createAccount>',
      newUserQuestion: 'New with us?',
      haveAccountQuestion: 'Have an account?',
      signUpLink: 'Registration',
      signInLink: 'Login'
    },

    PromoSuccessModal: {
      congrats: 'Congratulations!',
      defaultMessage: 'You participate in promo'
    },

    CancelReservationModal: {
      title: 'Cancel reservation',
      why: 'Please indicate the reason for cancelling the reservation',
      changeOfPlans: 'My plans have changed',
      mistake: 'I have made this reservation by a mistake',
      late: 'I\'m late and can\'t make it on specified time',
      changeTime: 'I want to choose a different time',
      other: 'Other',
      cancel: 'Cancel'
    },

    AllowGeolocationModal: {
      title: 'Access to geolocation data',
      text: 'In order to show you more relevant search results and distance to the restaurants please give the application an access to your geolocation',
      ok: '<base.ok>',
    },

    FeedbackForm: {
      whatAbout: 'Did you like it?',
      comment: 'Your comment:',
      commentPlaceholder: 'How did you like the place? (optional)',
      cancel: '<base.cancel>',
      send: 'Send'
    },

    ForgotPasswordModal: {
      title: 'Restore password',
      text: 'Enter your email',
      textSms: 'Enter your <types.user.phone>',
      email: '<types.user.email>',
      send: 'Send',
      sendAgain: 'Send again',
      back: '<base.back>',
      or: '<base.or>',
      codeReceived: 'I have received an SMS with a code',
    },

    NewPasswordModal: {
      title: 'Changing password',
      text: 'Enter the code from SMS',
      back: '<base.back>',
      code: 'Code from SMS',
      send: 'Send',
      newPassword: '<types.user.newPassword>',
      repeatPassword: '<types.user.repeatPassword>',
      successPasswordChange: 'The password was changed successfully',
    },

    ChangeCityPopup: {
      yourCity: 'Your city',
      yes: 'Yes',
      no: 'No, stay with',
      cities: {
        '@extends': 'cities'
      },
    },

    ReservationSuccessModal: {
      title: 'Congratulations!',
      p1: 'Reservation completed successfully! You will receive an SMS message after administrator approval.',
      p1noSms: 'The reservation has been completed successfully! If we have any questions, we will contact you.',
      p2: 'Sign up to get the most from Reservble.',
      register: 'Register',
    },

    CartModal: {
      title: 'Your order',
      total: '<base.total>',
      uah: '<currencies.uah>',
      empty: 'You did not select any dish yet',
      clear: 'Clear order',
    },

    Footer: {
      privacyPolicy: '<pages.Policy.privacy>',
      cookiePolicy: '<pages.Policy.cookies>',
      paymentsPolicy: '<pages.Policy.payments>',
      nearMe: 'Restaurants near me',
      openNow: 'Restaurants open now',
      topRated: 'Top rated',
      areYouRestaurant: 'Are you restaurant?',
      pricing: 'Plans and pricing',
      requestDemo: 'Request a demo',
    },

    SelectSpotModal: {
      title: 'Choosing a spot',
      book: '<base.book>',
      skip: 'Skip',
      uah: '<currencies.uah>',
      selectDepositType: 'Select type',
    },
  },

  components: {
    Feedback: {
      review: 'Your review',
      alreadyLeft: 'You have already left your review',
      empty: 'There are no reviews for this place yet',
      expand: 'Expand',
      collapse: 'Collapse',
    },
    Success: {
      successMsg: 'Success!',
    },
    InfoTags: {
      more: 'More'
    },
    TimeTags: {
      more: 'More'
    },
    CookieFooter: {
      message: 'This website uses cookies to ensure you get the best experience. You consent to our cookies if you continue to use our website.',
      learnMore: 'Learn more',
      okText: 'Ok'
    },
    ViewSelector: {
      map: 'Map',
      tiles: 'Tiles',
    },
    Distance: {
      m: 'm',
      km: 'km'
    },
    PlaceCard: {
      cancelReservation: 'Cancel reservation',
      reservationsToday: {
        1: 'person booked today',
        2: 'persons booked today',
        5: 'persons booked today',
      },
    },
    QueryResult: {
      results: {
        1: 'result found',
        2: 'results found',
        5: 'results found',
      },
    },
    SocialLoginButton: {
      text: 'Log in using <=provider>',
      google: 'Google',
      facebook: 'Facebook'
    },
    PlaceContacts: {
      address: 'Address:',
      contacts: 'Contacts:',
      webSite: 'Web-site:'
    },
    DetailedRating: {
      heading: 'Restaurant rating',
      kitchen: 'Kitchen',
      interior: 'Interior',
      service: 'Service',
      atmosphere: 'Atmosphere',
      noFeedbacks: '<components.Feedback.empty>',
      leaveFeedback: '<pages.PlaceInfo.leaveFeedback>',
      beTheFirst: '. Be the first!'
    },
    PersonsSelector: {
      placeholder: 'Persons',
      suffix: 'pers.',
      guests: 'Guests',
    },
    ReservationDetails: {
      persons: 'pers.'
    },
    DishCard: {
      uah: '<currencies.uah>',
      like: 'Favorites',
    },
    ViewOrder: {
      view: 'View order',
      uah: '<currencies.uah>'
    },
    Calendar: {
      date: 'Date',
    },
    TimeSelector: {
      time: 'Time',
    },
    CertificatesModal: {
      title: 'Certificates',
      buy: 'Buy',
      uah: '<currencies.uah>',
      subscription: 'Subscription',
      expand: 'Expand',
      days: {
        1: 'day',
        2: 'days',
        5: 'days',
      },
      visits: {
        1: 'visit',
        2: 'visits',
        5: 'visits',
      },
    },
  },

  pages: {
    Search: {
      noResults: 'There are no places matching your criteria',
      topLabel: 'One search, many possibilities',
    },

    Favorites: {
      empty: 'You don\'t have any favorite places yet',
      favorites: 'You have added',
      places: {
        1: 'place to favorites',
        2: 'places to favorites',
        5: 'places to favorites',
      },
    },

    Notifications: {
      youHave: 'You have',
      notifications: {
        1: 'new notification',
        2: 'new notifications',
        5: 'new notifications'
      },
      new: 'New',
      read: 'Read',
      reservation: {
        reserv: 'Your reservation in',
        atDate: 'at',
        for: 'for',
        persons: {
          1: 'person',
          2: 'persons',
          5: 'persons',
        },
        confirmed: 'was confirmed',
        rejected: 'was rejected',
        awaiting: 'is pending confirmation',
        cancelled: 'was cancelled',
        finished: 'successfully finished. We look forward to your next visit!',
        skipped: 'was marked as skipped',
        message: '. Message from administrator: "<=message>"',
        didYouLike: 'Enjoyed your visit?',
        leaveFeedback: 'Leave a feedback!'
      }
    },

    DemoStub: {
      message: '<base.anycafe> beta testing',
      open: 'We are officially open on ',
      openDate: '1 February 2020',
      countdown: {
        days: 'days',
        hours: 'hours',
        minutes: 'minutes',
        seconds: 'seconds'
      }
    },

    Error: {
      error404: 'Sorry but we could not find the page you are looking for',
      error500: {
        message: 'Well, you broke the internet!',
        details: 'Just kidding, looks like we have an internal issue, please try again in couple minutes'
      },
      entity404: 'The requested <=entity> is not found',
      reservationNotDraft: 'This reservation is not Draft anymore',
      goToReservations: 'Check "<containers.TopUserMenu.currentReserve>" page',
      goHome: 'Go back to home page',
    },

    Profile: {
      profile: 'My data',
      preferences: 'Preferences',
      feedback: 'My feedbacks',
      mailing: 'Mailing preferences',
      update: 'Data updated successfully'
    },

    FinishReservation: {
      almost: 'Almost done!',
      timer: {
        prefix1: 'We are holding a place',
        prefix2: 'for you for',
        suffix: 'minutes'
      },
      or: '<base.or>',
      name: '<types.user.name>',
      surname: '<types.user.surname>',
      email: '<types.user.email>',
      selectHall: 'You can choose hall:',
      depositOptions: 'Additional options:',
      depositsRequired: 'You must select at least one additional option to create a reservation',
      certificate: 'Certificate',
      certificateId: 'Certificate ID',
      certificateNotFound: 'Certificate with this number not found',
      uah: '<currencies.uah>',
      total: '<base.total>',
      comment: 'Your comment',
      subscribe: 'Subscribe for newsletters',
      notifications: 'Receive notifications',
      buttonName: '<types.reservation.button>',
      reservation: 'reservation',
      back: 'Back to restaurant',
      submit: '<types.reservation.button>',
      selectedHall: 'Selected hall',
      selectedSpots: 'Selected spots',
      deposits: 'Deposits',
    },

    Payment: {
      reservation: 'reservation',
      title: 'Deposit payment',
      uah: '<currencies.uah>',
      success: 'The payment was successful!',
      back: 'Back to restaurant',
      linkExpired: 'Your payment link is expired',
      tryAgain: 'Try making another reservation',
      tryAgainButton: 'Make another reservation',
      orderNumber: 'Order number',
      paymentTime: 'Time of payment',
      amount: 'Amount',
      waitFormLoad: 'Wait for the payment form to load without reloading the page',
      payWithPortmone: 'Pay with Portmone.com',
    },

    PlaceInfo: {
      cuisine: 'Cuisine',
      additional: 'Additional',
      payment: 'Payment options',
      certificates: 'Certificates and subscriptions',
      view: 'View',
      info: 'Info',
      menu: 'Menu',
      likedCategory: 'Favorites',
      description: 'Description',
      comments: 'Comments',
      status: {
        open: 'Open',
        closed: 'Closed'
      },
      closing: 'Closing:',
      opening: 'Opening:',
      averageBill: 'Average bill',
      place: 'place',
      leaveFeedback: 'Leave feedback',
      moreReviews: 'Show <=pageSize> more reviews',
      paidServices: 'Additional services',
      viewPaidServices: 'View services',
    },

    PlaceFeedback: {
      leaveFeedback: 'Leave feedback for',
      place: 'place',
      user: 'user',
    },

    MyReservations: {
      summary: {
        booked: {
          1: 'Booked',
          2: 'Booked',
          5: 'Booked',
        },
        tables: {
          1: 'spot',
          2: 'spots',
          5: 'spots',
        },
        pending: {
          1: ', pending confirmation for',
          2: ', pending confirmation for',
          5: ', pending confirmation for'
        },
      },
      inProcess: 'Requested',
      confirmed: 'Confirmed',
      empty: 'You don\'t have any active reservations'
    },

    ReservationHistory: {
      summary: {
        visits: 'You have made',
        reservations: {
          1: 'reservation',
          2: 'reservations',
          5: 'reservations',
        }
      },
      empty: 'You don\'t have any historical reservations'
    },

    Policy: {
      privacy: 'Privacy Policy',
      privacyGeneric: 'Privacy Policy',
      cookies: 'Cookie Policy',
      cookiesGeneric: 'Cookie Policy',
      payments: 'Payments Policy',
      meetings: 'Meeting Offer Agreement',
    },

    GptPlaceSearch: {
      back: 'Back to main page',
      title: 'Using AI magic',
      firstMessage: 'Hi, I am Reservble AI chatbot. Write the dish, cuisine, drink or experience you want to receive',
      placeholderMessages: 'Ask me: "Where to eat steak?"',
    },

    BuyCertificate: {
      title: 'Choosing certificate',
      checkPhone: 'This field is mandatory. Carefully check the specified phone number, we send you certificates by SMS',
      payWithPortmone: 'Pay with Portmone.com',
      fields: { '@extends': 'types.user' },
      errors: { '@extends': 'errors.validation' },
    }
  },

  actions: {
    user: {
      forgotPasswordSent: 'Password restore link was sent to your email',
      forgotSmsSent: 'We have sent you an SMS message with a code',
      passwordChanged: 'The password was changed successfully',
    }
  },

  legal: {
    heading: '<pages.Policy.privacy>',
    confirmation: {
      '@extends': 'pages.Policy',
      confirm1: 'Clicking the <=buttonName> button, you agree to the',
      confirm2: ' and our ',
      confirm3: ', including',
    }
  }
};
